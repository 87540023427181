import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../components/news-strip"

class ServicePage extends Component {

  componentDidMount() {
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Policies &amp; Documents" description="Explore a collection of policies and documents requested frequently from Tetley's Coaches - Leeds' oldest bus and coach company established 1949." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Policies &amp; Documents</h1>
            </div>

            <div className="news-article-body">

              <ul>
                <li><a href="/docs/2024-terms_and_conditions_of_hire.pdf">Terms and Conditions of Hiring</a></li>
                <li><a href="/docs/home-to-school-transport-terms-conditions-2022-06-20.pdf">Home-to-school Transport Terms and Conditions</a></li>
                <li><a href="/docs/passenger-collection-general-and-schools-risk-assessment-2024-2025.pdf">Passenger Collection General &amp; Schools Risk Assessment</a></li>
                <li><a href="/docs/operators_licence_2027_(60_coaches).pdf">Operator Licence (2027)</a></li>
                <li><a href="/docs/combined_liability_2024-2025.pdf">Combined Liability (2024-25)</a></li>
                <li><a href="/docs/2024-25_vehicle_insurance_certificate.pdf">Vehicle Insurance Certificate (2024-25)</a></li>
                <li><a href="/docs/dbs-assurance-form.pdf" title="DBS Assurance Declaration">DBS Assurance Declaration</a></li>
                <li><a href="/docs/25-tetleys-motor-services.pdf">CPT Membership (2025)</a></li>
                <li><a href="/docs/psvar-exemption-certificate.pdf">PSVAR Exemption Certificate</a></li>
              </ul>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default ServicePage
